.cockpitTopPanel {
  padding: 80px 0 46px;
}

.learnButtonLinkColor,
.learnButtonLinkColor:hover {
  color: var(--not-changine-white);
}

.buttonDisable {
  pointer-events: none;
}

.rulesList {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.cardFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}

.learnButton {
  width: 100%;
}

.cardFooter .rulesList {
  margin-right: 35px;
}

.cardFooter > div {
  margin: 0 40px 0 0;
}

.jList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.jList .jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 290px;
  padding: 28px;
  background-color: var(--lavender-grey);
  margin-right: 0;
  border-radius: 10px;
}

.jList .jumbotron:first-child {
  max-width: 55%;
}

.jList .jumbotron:not(:last-child) {
  margin-right: 20px;
}

.panelCount {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 100px;
  color: var(--neutral-gray-blue);
}

.panelTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--neutral-dark-blue);
}

.jumbotronHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.jumbotronHeaderItem {
  max-width: 60%;
}

.panelDate,
.panelTime {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.panelDate {
  color: var(--neutral-dark-blue);
}

.panelTime {
  color: var(--neutral-dark-blue-50);
}

.daysContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}

.daysValue {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 92px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.daysLabel {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.mobileJumbotron {
  display: none;
}

.jList .mobileJumbotron .jumbotron {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--neutral-light-gray);
}

.mobileJumbotron .panelTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}

.mobileJumbotron .daysValue,
.mobileJumbotron .daysLabel {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.mobileJumbotron .daysContent {
  margin-bottom: 0;
}

.bestResultCardContainer {
  margin-right: 20px;
}

@media (max-width: 1300px) {
  .rulesListItem:not(:last-child) {
    margin-right: 15px;
  }

  .cardFooter .rulesList {
    margin-right: 15px;
  }
}

@media (max-width: 1199px) {
  .bestResultCardContainer {
    margin: 15px auto 0;
    max-width: 400px;
  }

  .cardFooter > div {
    margin: 20px 0 40px;
  }

  .cardFooter {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .jList {
    flex-flow: row wrap;
    max-width: 550px;
    margin: 0 auto;
  }

  .jList .jumbotron,
  .jList .jumbotron:first-child {
    max-width: 500px;
    width: 100%;
    margin: 15px auto 0;
    flex-grow: 1;
  }

  .jList .jumbotron:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .cardFooter .rulesList {
    width: 100%;
    justify-content: center;
    margin-bottom: 28px;
    margin-right: 0;
  }

  .cardFooter > :global(.btn) {
    width: 100%;
  }

  .daysContent {
    justify-content: center;
  }

  .panelCount {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .cockpitTopPanel {
    padding: 0;
  }

  .jList .jumbotron {
    background-color: var(--whiteColor);
    padding: 0;
  }

  .jList .jumbotron:nth-child(2),
  .jList .jumbotron:nth-child(3) {
    display: none;
  }

  .jList .jumbotron:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .mobileJumbotron {
    display: block;
    margin: 10px 0;
  }
}
