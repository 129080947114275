:root {
  --filter-bright-color-PNG: 1;
  --whiteColor: #fff;
  --box-shadow: rgba(0, 0, 0, 0.11);
  --footer-text: rgba(75, 101, 132, 0.7);
  --white-with-opacity: rgba(255, 255, 255, 0.3);
  --blackColor: #000;
  --not-changine-black: #000;
  --not-changine-white: #fff;
  --theme-primary-80: rgba(0, 128, 201, 0.8);
  --lavender-grey: #f7f9fc;
  --yellow-white: #fee801;
  --additional-blue: #cde1ff;
  --additional-gray-blue: #e2eaf6;
  --additional-gray-blue-20: rgba(226, 234, 246, 0.2);
  --additional-light-blue: #e4efff;
  --blue-twinkle: #d1d8e0;
  --button-default-disabled: #80bbde;
  --button-default-enabled: #0080c9;
  --button-default-focused: #0078bd;
  --button-default-focused-10: #f0f6ff;
  --button-default-pressed: #0071b1;
  --button-default-pressed-15: #ceedff;
  --button-icon-enabled: #e4efff;
  --button-icon-focused: #f0f6ff;
  --button-icon-pressed: #0080c9;
  --button-outline-focused: #e8f5fc;
  --button-primary-disabled: #fff380;
  --button-primary-enabled: #fee801;
  --button-primary-focused: #f4df01;
  --button-primary-focused-10: #fefce6;
  --button-primary-pressed: #ead501;
  --button-primary-pressed-15: #fcf9d9;
  --not-answer: #ffc107;
  --button-secondary-disabled: #f1f5fa;
  --button-secondary-enable: #e2eaf6;
  --button-secondary-focused: #dae2ee;
  --button-secondary-pressed: #d3deed;
  --functional-error-10: rgba(255, 124, 148, 0.1);
  --functional-error-15: rgba(255, 124, 148, 0.15);
  --functional-success: #15cf74;
  --functional-success-bold: #28a745;
  --functional-success-10: rgba(21, 207, 116, 0.1);
  --functional-warning: #ff7d95;
  --functional-warning-light: rgba(255, 125, 149, 0.53);
  --functional-warning-bold: #e20000;
  --gray-6: #f2f2f2;
  --neutral-dark-blue: #000;
  --neutral-dark-blue-70: #000;
  --neutral-dark-blue-50: #000;
  --neutral-gray-blue: #000;
  --neutral-gray-blue-50: #000;
  --neutral-gray: #d1d8e0;
  --dark-mode-input-placeholder: #9b9ea5;
  --neutral-light-gray: #f7f9fc;
  --neutral-light-gray-no-opacity: #f7f9fc;
  --text-color: #000;
  --badge-color: #29b1ff;
  --light-white: #fafafa;
  --placeholder-color: rgba(75, 101, 132, 0.8);
  --modal-btn-color: rgba(255, 255, 255, 0.6);
  --modal-footer-btn-color: #0e71b8;
  --theme-accent: #fee801;
  --theme-primary: #0080c9;
  --badge-category-color: #fad978;
  --wrong-game-button: #e03d24;
  /*--body-color: #e9f6fe;*/
  --body-color: #fff;
}
