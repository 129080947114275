.container {
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--text-color);
}

.listWrapper {
  width: 50%;
  padding-left: 60px;
}

.listWrapper li,
.listWrapper a {
  margin: 8px 0;
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.listWrapper a {
  text-decoration: underline;
}

.listWrapper li svg,
.listWrapper a svg {
  margin-right: 10px;
}

.container img {
  max-width: 50%;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.buttonWrapper :global(.btn) {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 50px;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
    gap: 20px 0;
  }

  .listWrapper {
    width: 100%;
    padding-left: 0;
  }

  .container img {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .listWrapper li {
    margin: 8px 0 8px 6px;
  }

  .listWrapper li svg {
    margin-top: 4px;
  }

  .listWrapper li div {
    display: flex;
  }
}
