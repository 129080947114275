.termPage {
  padding: 60px 0;
}

.termPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.termPageContentMobile {
  display: none;
}

.termPageContentMobile :global .faqHeader {
  text-transform: lowercase;
}

.termPageContentMobile :global .collapsibleItem:first-letter {
  text-transform: uppercase;
}

.termPage :global .list-group {
  min-width: 280px;
  margin-right: 33px;
  flex-grow: 1;
}

.termPage :global .list-group-item {
  border: none;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  padding: 5px 0;
  margin-bottom: 18px;
}

:global(.dark-theme) .termPage :global .list-group-item {
  color: var(--text-color);
  background-color: var(--whiteColor);
}

.termPage :global .list-group-item:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.termPageText {
  column-count: 2;
  column-width: 350px;
  column-gap: 20px;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.mobileText {
  display: none;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
}

.glossaryScrollBox {
  max-height: 700px;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.glossaryScrollBox::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.glossaryScrollBox::-webkit-scrollbar {
  width: 12px;
}

.glossaryScrollBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .mobileText {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .termPage {
    padding: 0;
  }

  .termPageContent {
    display: none;
  }

  .termPageContentMobile {
    display: block;
  }
}
